exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-case-study-anpr-tsx": () => import("./../../../src/pages/case-study/anpr.tsx" /* webpackChunkName: "component---src-pages-case-study-anpr-tsx" */),
  "component---src-pages-case-study-boo-chat-tsx": () => import("./../../../src/pages/case-study/boo-chat.tsx" /* webpackChunkName: "component---src-pages-case-study-boo-chat-tsx" */),
  "component---src-pages-case-study-queue-app-tsx": () => import("./../../../src/pages/case-study/queue-app.tsx" /* webpackChunkName: "component---src-pages-case-study-queue-app-tsx" */),
  "component---src-pages-case-study-tsx": () => import("./../../../src/pages/case-study.tsx" /* webpackChunkName: "component---src-pages-case-study-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */)
}

